import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";

import gsap from "gsap";
import imagesLoaded from "imagesloaded";
import parallaxAnimation from "../utils/parallaxAnimation.js";

import { createLocaleTextGetter } from "../utils";
import ActivitiesSlider from "../components/blocks/ActivitiesSlider";

import "../styles/pages/activities.scss";
import CoverImageDesk from "../images/pages/activities/desktop/activities_cover.jpg";
import CoverImageMobile from "../images/pages/activities/mobile/activities_cover_mobile.jpg";

const ActivitesPage = ({ data, pageContext, context }) => {
  const localeTextGetter = createLocaleTextGetter(pageContext.locale);
  const sliderWrapper = React.createRef();

  let splittedData = [];

  React.useEffect(() => {
    const textAnimation = require("../utils/textAnimation.js").default;
    const intersectionAnimation =
      require("../utils/intersectionAnimation.js").default;
    if (window.innerWidth > 769) {
      let tl = gsap.timeline({});
      tl.set("main", { autoAlpha: 0 });
      imagesLoaded(".bi_activities_header", () => {
        splittedData = textAnimation.splitAllText();

        tl.set("main", { autoAlpha: 1 });
        /* tl.pause();
        setTimeout(() => {
          tl.play();
        }, 500); */
        textAnimation.splitTitleApparition(
          splittedData.splittedTitles.find((el) => el.id === "h1").chars,
          tl
        );
        textAnimation.splitBlockApparition(
          splittedData.splittedBlocks.find((el) => el.id === "header").lines
            .lines,
          "-=0.5",
          "word",
          tl
        );
        tl.fromTo(
          ".bi_activities_header picture",
          { autoAlpha: 0, y: 100 },
          {
            autoAlpha: 1,
            y: 0,
            duration: 1,
            ease: "power2.inOut",
            stagger: 0.2,
          },
          "-=1"
        );
        parallaxAnimation.imagesParallax();
        parallaxAnimation.blockParallax();
        intersectionAnimation(splittedData);
      });
    }
  }, []);
  return (
    <main className="bi_activities">
      <Helmet htmlAttributes={{ lang: pageContext.locale }}>
        <title>
          {pageContext.locale === "fr"
            ? "Activitées | Les Suites de l'île Barbe"
            : "Activities | The Suites of Île Barbe"}
        </title>
        <meta
          type="description"
          content={
            pageContext.locale === "fr" ? "Les activitées près de l'île barbe" : "Activities near the Île Barbe"
          }
        />
      </Helmet>
      <div className="bi_activities_header bi_pageA_header">
        <h1 data-splitted-title data-s-tag="h1">
          {pageContext.locale === "fr" ? 'Activités' : 'Activities'}
        </h1>
        <p data-splitted-block data-s-tag="header">
          {
            pageContext.locale === "fr" ? "Situées au milieu de la Saône, sur une île privée, les Suites de l’Île Barbe bénéficient d’un environnement exceptionnel, parfait pour profiter des activités offertes par la rivière et des nombreux points d’intérêt de la ville de Lyon et de la région:" : "Located in the middle of the Saône, on a private island, the Suites de l'Île Barbe benefit from an exceptional environment, perfect for taking advantage of the activities offered by the river and the many points of interest of the city of Lyon and the region:"
          }
        </p>
        <picture
          data-parallax="true"
          data-p-direction="bottom"
          data-p-vitesse="0.5"
        >
          <source srcSet="" media="(min-width: 768px)" />
          <source srcSet={CoverImageMobile} media="(max-width: 767px)" />
          <img src={CoverImageDesk} alt="Restauration" />
        </picture>
      </div>
      <section
        className="bi_activities_slider"
        ref={sliderWrapper}
        data-intersection-animation
        data-i-trigger=".bi_activities_slider"
        data-i-type="opacity"
        data-i-value="10"
        data-i-duration="1"
      >
        <ActivitiesSlider gsap={context ? context.gsap : gsap} activities={data.sanityActivitiesDocumentType.suites} localeTextGetter={localeTextGetter} />
      </section>
    </main>
  );
};

export default ActivitesPage;

export const pageQuery = graphql`
  query ActivitiesQuery{
    sanityActivitiesDocumentType {
      id
      suites {
        description {
          _key
          _type
          fr
          en
        }
        image {
          Image {
            asset {
              gatsbyImageData
            }
          }
          alt {
            _key
            _type
            fr
            en
          }
        }
        title {
          _key
          _type
          fr
          en
        }
      }
    }
  }
`