import gsap from "gsap";
import textAnimation from "./textAnimation";

export const opacityAnimation = (element) => {
  let dataSet = element.dataset;

  gsap.fromTo(
    element,
    {
      opacity: 0,
      y: dataSet.iValue ? dataSet.iValue : 100,
    },
    {
      opacity: 1,
      y: 0,
      duration: dataSet.iDuration ? dataSet.iDuration : 1,
      ease: "power2.inOut",
      delay: dataSet.iDelay ? dataSet.iDelay : 0,
      clearProps: 'transform',
    }
  );
};

const initIntersection = (splittedData) => {
  // get all elements with the data attribute "data-intersection-animation"
  let elements = document.querySelectorAll(`[data-intersection-animation]`);
  for (let index = 0; index < elements.length; index++) {
    const element = elements[index];
    // get the data attribute "data-intersection-trigger"
    const triggerElement = element.getAttribute("data-i-trigger");
    // get the data attribute "data-intersection-type"
    const typeElement = element.getAttribute("data-i-type");
    // get the data attribute "data-intersection-tag"
    const tag = element.getAttribute("data-s-tag");

    switch (typeElement) {
      case "text":
        // create intersection observer on the trigger element and call the corresponding callback
        const textObserver = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              let tl = gsap.timeline({
                delay: element.dataset.iDelay ? element.dataset.iDelay : 0,
              });
              tl.set(
                splittedData.splittedBlocks.find((el) => el.id === tag).lines
                  .lines,
                { opacity: 0 }
              );
                tl.set(
                  splittedData.splittedBlocks.find((el) => el.id === tag).lines
                    .lines,
                  { opacity: 1, delay: 1 }
                );
                textAnimation.splitBlockApparition(
                  splittedData.splittedBlocks.find((el) => el.id === tag).lines
                    .lines,
                  "-=0",
                  "word",
                  tl
                );
              textObserver.unobserve(document.querySelector(triggerElement));
            }
          });
        });
        textObserver.observe(document.querySelector(triggerElement));

        break;
      case "title":
        // create intersection observer on the trigger element and call the corresponding callback
        const titleObserver = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              textAnimation.splitTitleApparition(
                splittedData.splittedTitles.find((el) => el.id === tag).chars,
                null
              );
              titleObserver.unobserve(document.querySelector(triggerElement));
            }
          });
        });
        titleObserver.observe(document.querySelector(triggerElement));
        break;
      case "opacity":
        // create intersection observer on the trigger element and call the corresponding callback
        const opacityObserver = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              opacityAnimation(element);
              opacityObserver.unobserve(document.querySelector(triggerElement));
            }
          });
        });
        opacityObserver.observe(document.querySelector(triggerElement));
        break;

      default:
        break;
    }
  }
};

export default initIntersection;
