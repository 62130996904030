import React, { useEffect, useState, useRef } from "react";
import ScrollTrigger from "gsap/ScrollTrigger";
import imagesLoaded from "imagesloaded";
import { GatsbyImage } from "gatsby-plugin-image";

const importAll = (r) => {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};

const images = importAll(
  require.context(
    "../../images/pages/activities/desktop",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

const imagesMobiles = importAll(
  require.context(
    "../../images/pages/activities/mobile",
    false,
    /\.(png|jpe?g|svg)$/
  )
);


const ActivitiesSlider = ({ gsap, activities, localeTextGetter }) => {
  const sliderWrapper = useRef();

  const AnimateOnScroll = (tl, element, index) => {
    if (index === 0) {
      gsap.set(
        sliderWrapper.current.querySelectorAll(
          ".activities_slider_texts > div"
        )[index],
        { opacity: 1 }
      );
      gsap.set(
        element.querySelector(
          ".activites_slider_pictures .gatsby-image-wrapper"
        ),
        {
          opacity: 1,
          scale: 1.2,
          translateY: 0,
        }
      );
      tl.fromTo(
        sliderWrapper.current.querySelectorAll(
          ".activities_slider_texts > div"
        )[index],
        { opacity: 1, y: 0 },
        {
          opacity: 0,
          y: -50,
          duration: 0.5,
          delay: 2,
          onComplete: () => {
            gsap.killTweensOf(
              sliderWrapper.current.querySelectorAll(
                ".activities_slider_texts > div"
              )
            );
          },
        }
      );
      tl.addLabel("start");
    }

    if (index === 0) {
    } else {
      tl.fromTo(
        sliderWrapper.current.querySelectorAll(
          ".activities_slider_texts > div"
        )[index],
        {opacity:0}, { opacity: 1, duration: 0 }
      );
      tl.add(() => {
        let tl1 = gsap.timeline();
        tl1.fromTo(
          sliderWrapper.current
            .querySelectorAll(".activities_slider_texts > div")
            [index].querySelectorAll("h2 .word"),
          { opacity: 0, y: 40 },
          { opacity: 1, y: 0, stagger: 0.1 }
        );
        tl1.fromTo(
          sliderWrapper.current
            .querySelectorAll(".activities_slider_texts > div")
            [index].querySelectorAll("p .bi-line"),
          { opacity: 0, y: 20 },
          {
            opacity: 1,
            y: 0,
            stagger: 0.1,
            onComplete: () => {
              tl1.kill();
            },
          }
        );
      });
      tl.fromTo(
        element.querySelector(`.gatsby-image-wrapper`),
        { scale: 1, translateY: "100vh" },
        {
          scale: 1.2,
          translateY: 0,
        }
      );
      tl.fromTo(
        sliderWrapper.current.querySelectorAll(
          ".activities_slider_texts > div"
        )[index],
        { opacity: 1, y: 0 },
        {
          opacity: 0,
          y: -50,
          duration: 0.5,
          delay: 2,
          onComplete: () => {
            gsap.killTweensOf(
              sliderWrapper.current.querySelectorAll(
                ".activities_slider_texts > div"
              )
            );
          },
        }
      );
    }
  };

  useEffect(() => {
    // gsap.registerPlugin(ScrollTrigger);
    gsap.killTweensOf(sliderWrapper.current, "#pined");

    if (window.innerWidth > 770) {
      let tl;
      setTimeout(() => {
        imagesLoaded(sliderWrapper.current, () => {
          tl = gsap.timeline({
            scrollTrigger: {
              trigger: sliderWrapper.current,
              start: "top top",
              end: "+=9600vh",
              scrub: true,
              pin: "#pined",
            },
          });
          sliderWrapper.current
            .querySelectorAll(".activites_slider_pictures picture")
            .forEach((el, index) => {
              AnimateOnScroll(tl, el, index);
              ScrollTrigger.refresh();
            });
        });
      }, 500);
      return () => {
        tl.kill();
      };
    }
  }, [gsap]);

  return (
    <div className="gsap_wrapper" ref={sliderWrapper}>
      <div className="activities_slider pined" id="pined">
        <div className="activites_slider_pictures">
          {activities.map((activity, index) => {
            return (
              <picture>
                <GatsbyImage
                  key={index}
                  image={activity.image.Image.asset.gatsbyImageData}
                  alt={localeTextGetter(activity.image.Image.alt)}
                />
              </picture>
            );
          })}
          ;
        </div>
        <div className="activities_slider_texts">
          {activities.map((activity, index) => {
            return (
              <div key={index}>
                <picture>
                  <GatsbyImage
                    image={activity.image.Image.asset.gatsbyImageData}
                    alt={localeTextGetter(activity.image.Image.alt)}
                  />
                </picture>
                <h2 data-splitted-title data-s-tag={"h2-" + (index + 1)}>
                  {localeTextGetter(activity.title)}
                </h2>
                <p data-splitted-block data-s-tag={"p2-" + (index + 1)}>
                  {localeTextGetter(activity.description)}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ActivitiesSlider;
